<template lang="pug">
  #app
    router-view
</template>
<script>
export default {
  mounted() {
    console.log(this)
  }
}
</script>
<style lang="stylus">
@import './styles/_reset.styl'

#app
  font-family Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
</style>
