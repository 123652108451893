(function (doc, win) {
  const docEl = doc.documentElement
  const resizeEvt = 'onorientationchange' in window ? 'onorientationchange' : 'resize'
  const recall = function () {
    const clientWidth = docEl.clientWidth
    if (!clientWidth) {
      return
    }
    if (clientWidth >= 750) {
      docEl.style.fontSize = '100px'
    } else {
      docEl.style.fontSize = 100 * (clientWidth / 750) + 'px'
    }
  }
  if (!doc.addEventListener) {
    return
  }
  win.addEventListener(resizeEvt, recall, false)
  doc.addEventListener('DOMContentLoaded', recall, false)
  window.onresize = function () {
    recall()
  }
})(document, window)
document.addEventListener('DOMContentLoaded', function () {
  if (!document.documentElement.style.fontSize) {
    setTimeout(function () {
        window.location.reload()
    })
  }
}, false)
