import Vue from 'vue'
import Inter from '@webfront-components-vue/vue-internationalization'

Vue.use(Inter)

export default new Inter({
  lang: 'en',
  /* eslint-disable */
  langWords: {
    //首页
    "Search ang get": {
      "en": "Search and get solutions at Answered!",
      "id": "Cari dan dapatkan jawaban di Answered!"
    },
    "unlock all solutions": {
      "en": " unlock all solutions in Answered! the app.",
      "id": "Dapatkan semua jawaban di aplikasi Answered!"
    },
    "placeholder": {
      "en": "Please enter the question you need to search",
      "id": "Silakan masukkan pertanyaan yang ingin kamu cari",
    },
    "SNAP AND GET": {
      "en": "<b>SNAP AND GET</b>",
      "id": "<b>FOTO DAN</b> <br><span>DAPATKAN</span>",
    },
    "ANSWERS":{
      "en":"ANSWERS",
      "id":"JAWABAN"
    },
    "details":{
      "en":"Search the solution by taking a photo of your problem.",
      "id":"Cari solusi dengan mengambil foto masalah anda"
    },
    "forfree":{
      "en":"Download Answered! <span>for Free</span> Right Now",
      "id":"Download Answered! <span>Free</span> Sekarang "
    },
    "mforfree":{
      "en":"Download <span>Answered!</span> <br>For More Features",
      "id":"Download <span>Answered!</span> <br>Dapatkan lebih banyak fitur "
    },
    "p3_title":{
      "en":"Big Question Bank",
      "id":"Bank soal besar"
    },
    "p3_sub_title":{
      "en":"Just snap a picture, we will find the match for you.",
      "id":"Ambil foto, dan kami akan mencarikan yang cocok untuk anda"
    },
    "questions":{
      "en":"Questions",
      "id":"Pertanyaan"
    },
    "pictOne_title":{
      "en":"Get <span>Step by Step</span><br>Explanations",
      "id":"Dapatkan penjelasan<br>secara bertahap"
    },
    "pictOne_sub_title":{
      "en":"Learn how to solve problems through clear<br>steps and detailed instructions.",
      "id":"Belajar bagaimana cara menyelesaikan masalah secara jelas dengan instruksi yang mendetail. "
    },
    "pictTwo_title":{
      "en":"Web Results for<br><span>More Information</span>",
      "id":"Hasil web untuk informasi lebih lanjut"
    },
    "pictTwo_sub_title":{
      "en":"Want more information? No need to type any word. <br>We have got results from search engine for you.",
      "id":"Ingin informasi lebih lanjut? Tidak perlu mengetik kata apa pun.<br>Kami mendapatkan hasil dari mesin pencari untuk anda."
    },
    "pictThree_title":{
      "en":"Users<br><span>Feedback</span>",
      "id":"Pengguna<br><span>Masukan</span>"
    },
    "pictThree_sub_title":{
      "en":"Hear from other users.",
      "id":"Dengar dari pengguna"
    },
    "nameone":{
      "en":"Jasmin Susanti from Bali",
      "id":"Jasmin Susanti dari Bali "
    },
    "contentone":{
      "en":"It's great to see that 10th-grade students can now get solutions so quick when they get stuck.",
      "id":"Sangat menyenangkan melihat siswa kelas 10 sekarang bisa mendapatkan solusi dengan sangat cepat ketika mereka mengalami kebuntuan."
    },
    "nametwo":{
      "en":"Shalini Wadkar from Jambi",
      "id":"Shalini Wadkar dari Jambi"
    },
    "contenttwo":{
      "en":"Some books only have answers to questions but no explanations. Thanks to Answered, now I can get detailed explanations easily.",
      "id":"Beberapa buku hanya memiliki jawaban atas pertanyaan tetapi tidak ada penjelasannya. Berkat Answered, sekarang saya bisa mendapatkan penjelasan mendetail dengan mudah."
    },
    "namethree":{
      "en":"Adarsh Ghosal from Papua",
      "id":"Adarsh ​​Ghosal dari Papua"
    },
    "contentthree":{
      "en":"As a parent, I often need to check my children's homework. However I sometimes feel unfamiliar with some knowledge. Answered really helps a lot.",
      "id":"Sebagai orang tua, saya sering perlu memeriksa pekerjaan rumah anak-anak saya. Namun terkadang saya merasa tidak terbiasa dengan beberapa pengetahuan. Answered sangat membantu."
    },
    "New quetions": {
      "en": "<span>New quetions</span><br>asked by Answered! users",
      "id": "<span>Pertanyaan baru</span><br>ditanyakan oleh pengguna"
    },
    "See other asked": {
      "en": "See other asked questions",
      "id": "Lihat pertanyaan yang lain",
    },
    "search": {
      "en": "search",
      "id": "Cari",
    },
    //列表页
    "tryMore": {
      "en": "Try more features <br>at Answered!",
      "id": "Coba lebih banyak <br>fitur di Answered!",
    },
    "descItem-one": {
      "en": "Search by problem image",
      "id": "Cari dengan gambar/foto soal",
    },
    "descItem-two": {
      "en": "Unlock detailed explanation",
      "id": "Dapatkan penjelasan detail",
    },
    "descItem-three": {
      "en": "Unlock more solutions",
      "id": "Dapatkan jawaban lain",
    },
    "descItem-four": {
      "en": "Translate any English text by problem image",
      "id": "Terjemahkan teks bahasa Inggris apapun dengan gambar/foto soal",
    },
    "problems": {
      "en": "Problems people asked",
      "id": "Soal yang ditanyakan orang lain.",
    },
    "tryagain": {
      "en": "Get problems failed, please try again later.",
      "id": "Gagal mendapatkan soal",
    },
    "button-try_again": {
      "en": "Try again",
      "id": "Coba lagi",
    },
    "Similar problems": {
      "en": "Similar problems",
      "id": "Soal serupa",
    },
    "Problem": {
      "en": "Problem",
      "id": "Soal",
    },
    "Answer": {
      "en": "Answer",
      "id": "Jawaban",
    },
    "not your question": {
      "en": "Not your question?",
      "id": "Bukan pertanyaan kamu?",
    },
    "unlock question": {
      "en": "Unlock more questions and solutions",
      "id": "Dapatkan lebih banyak pertanyaan dan jawaban",
    },
    "answered by": {
      "en": "Answered by ",
      "id": "Dijawab oleh ",
    },
    "Answer:": {
      "en": "Answer :",
      "id": "Jawaban :",
    },
    "Explanation": {
      "en": "Explanation:",
      "id": "Penjelasan:",
    },
    "Check solution": {
      "en": "Check solution<div class='icon'></div>",
      "id": "Periksa jawaban"
    },
    "remove mosaic": {
      "en": "Remove <div class='mosaic'></div> and get complete answer",
      "id": "Hapus <div class='mosaic'></div> dan dapatkan jawaban lengkap",
    },
    "Try it later": {
      "en": 'Try it later',
      "id": 'Coba lagi nanti',
    },
    "noProblems": {
      "en": 'No problems found',
      "id": 'Tidak ada pertanyaan yang ditemukan',
    },
  }
})
