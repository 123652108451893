import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const store = () =>
  new Vuex.Store({
    state: {
      isTryMore: false,
      loading: false
    },
    mutations: {
      isTryMore(state, isTryMore) {
        state.isTryMore= isTryMore;
      },
      showLoading(state){
        state.loading = true
      },
      hideLoading (state) {
          state.loading = false
      }
    },
    actions: {
      isTryMore(isTryMore, Blur) {
        isTryMore.commit("isTryMore", Blur);
      },
    },
    getters: {
    },
  })

export default store;
