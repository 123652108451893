import Vue from 'vue'
import inter from '@/config/inter'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/reset.css'
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/main.css'
import {Dropdown,DropdownMenu,DropdownItem,} from 'element-ui';
import MathJax from './util/mathJax.js'
import MetaInfo from "vue-meta-info"; //seo优化
import infiniteScroll from 'vue-infinite-scroll'
import './util/rem'

Vue.use(MetaInfo);
Vue.prototype.MathJax = MathJax
Vue.config.productionTip = false
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(infiniteScroll)

new Vue({
  router,
  inter,
  store,
  render: (h) => h(App),
}).$mount('#app')
