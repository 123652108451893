import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

let flag = false
const routes = [
  {
    path: '/',
    name: 'Home',
    component:() =>  import('../views/Home.vue'),
  },
  {
    path:'/m',
    name:'mHome',
    component:() =>import('../views/m/Home.vue')
  },
  {
    path: '/quesList',
    name: 'quesList',
    component:() => import('../views/quesList.vue')
  },
  {
    path: '/quesDetails',
    name: 'quesDetails',
    component:() => import('../views/quesDetails.vue')
  },
  {
    path: '/m/quesList',
    name: 'mQuesList',
    component:() => import('../views/m/quesList.vue')
  },
  {
    path: '/m/quesDetails',
    name: 'mQuedDetails',
    component:() => import('../views/m/quesDetails.vue')
  },
  {
    path:'/m/:lang',
    name:'mHome',
    component:() =>import('../views/m/Home.vue')
  },
  {
    path: '/:lang',
    name:'Home',
    component:() => import('../views/Home.vue'),
  },
  {
    path: '/quesList/:lang',
    name: 'quesList',
    component:() => import('../views/quesList.vue')
  },
  {
    path: '/quesDetails/:lang',
    name: 'quesDetails',
    component:() => import('../views/quesDetails.vue')
  },
  {
    path: '/m/quesList/:lang',
    name: 'mQuesList',
    component:() => import('../views/m/quesList.vue')
  },
  {
    path: '/m/quesDetails/:lang',
    name: 'mQuedDetails',
    component:() => import('../views/m/quesDetails.vue')
  }
]

const router = new VueRouter({
  //上线时改为history,
  mode: 'history',
  routes,

})

router.beforeEach((to,from,next)=>{
  if(flag){
    next()
    return
  }
  // debugger
if(((to.path.indexOf('/m') > -1 || to.path.indexOf('/m/') > -1) && !navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
    flag = true
    let url = to.path.substring(2)
    router.push(url)
  }
//pc端到移动端
if(((to.path.indexOf('/m') < 0 || to.path.indexOf('/m/') < 0) && navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))){
  flag = true
  let path = '/m'
  if (to.query.lang) {
    path += `/${to.query.lang}`
  }
  router.push(path)
}
next()
})


export default router


